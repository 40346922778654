import { sumBy } from "lodash";
import { dateConfig } from "../../utils/dateconfig";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import Firma from "../../components/Firma";

const ComprobantePrintTicket = ({ data }) => {
  if (!data.recibo) {
    return (
      <div style={{ fontFamily: "Arial, Verdana, Helvetica", color: "#000" }}>
        <div className="">
          <div className="py-2">
            <div className="text-center text-xl font-bold">
              COMPROBANTE DE CUENTAS POR COBRAR
            </div>
            <div className="text-center text-xs font-bold">Nombre empresa</div>
          </div>
          <div className="text-center text-xs font-bold mb-1">
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          </div>
          <div className="text-xs pl-2">
            <div className="text-xs mb-1">
              Número de orden:{" "}
              {fInvoiceNumber(
                data.voucher_number ?? data.voucher_head.voucher_number
              )}
            </div>
            <div className="text-xs mb-1">
              Cliente: {data.client.business_name}
            </div>
            <div className="text-xs mb-1">
              NIT/CI: {data.client.identification_number}
            </div>
            {/* <div>Numero de Orden: {fInvoiceNumber(dataPrint.voucher_number)}</div> */}
            <div>Fecha: {dateConfig(data.created_at)}</div>
          </div>
          <div className="border-double border-t-4 border-b-4 border-l-0 border-r-0 border-gray-900 my-3">
            <div className="flex text-sm pt-1 px-1">
              <span className="w-3/6">DETALLE</span>
            </div>
            <div className="border-dashed border-t border-b border-l-0 border-r-0 border-gray-900 mt-1 my-2 py-2 px-1">
              {data.destail}
            </div>
          </div>
          <div className="text-xs">
            <div className="mb-1">
              Total {data.currency}:{" "}
              {fNumber(parseFloat(data.total) / parseFloat(data.exchange_rate))}
            </div>
            <div className="mb-1">
              TIPO DE CAMBIO Bs: {fNumber(data.exchange_rate)}
            </div>
            <div className="mb-1">
              Total: {data.currency}: {fNumber(data.total)}
            </div>
            <div className="mb-1">A cuenta: {fNumber(data.a_cuenta)}</div>
            <div className="mb-40">
              Saldo:{" "}
              {fNumber(parseFloat(data.total) - parseFloat(data.a_cuenta))}
            </div>
            <div class="flex justify-between">
              <div class="w-1/2 ">
                <Firma
                  title="Realizado por"
                  user={data.user.name}
                  url={data.user?.firma?.firma}
                />
              </div>
              <div class="w-1/2">
                <Firma title="cliente" client={data.client.business_name} />
              </div>
            </div>
            <div className="text-right">
              <div>Fecha: {dateConfig(data.created_at)}</div>
              <div className="font-bold text-sm"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ fontFamily: "Arial, Verdana, Helvetica", color: "#000" }}>
      <div className="">
        <div className="py-2">
          <div className="text-center text-xl font-bold">
            COMPROBANTE DE CUENTAS POR COBRAR
          </div>
          <div className="text-center text-xs font-bold">Nombre empresa</div>
        </div>
        <div className="text-center text-xs font-bold mb-1">
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        </div>
        <div className="text-xs pl-2">
          <div className="text-xs mb-1">
            Número de orden: {fInvoiceNumber(data.voucher_number)}
          </div>
          <div className="text-xs mb-1">
            Cliente: {data.client.business_name}
          </div>
          <div className="text-xs mb-1">
            NIT/CI: {data.client.identification_number}
          </div>
          {/* <div>Numero de Orden: {fInvoiceNumber(dataPrint.voucher_number)}</div> */}
          <div>Fecha: {dateConfig(data.created_at)}</div>
        </div>
        <div className="border-double border-t-4 border-b-4 border-l-0 border-r-0 border-gray-900 my-3">
          <div className="flex text-sm pt-1 px-1">
            <span className="w-3/6">DETALLE</span>
          </div>
          <div className="border-dashed border-t border-b border-l-0 border-r-0 border-gray-900 mt-1 my-2 py-2 px-1">
            {data.recibo.destail}
          </div>
        </div>
        <div className="text-xs">
          <div className="mb-1">
            Total {data.recibo.currency}:{" "}
            {fNumber(
              parseFloat(data.recibo.total) /
                parseFloat(data.recibo.exchange_rate)
            )}
          </div>
          <div className="mb-1">
            TIPO DE CAMBIO Bs: {fNumber(data.recibo.exchange_rate)}
          </div>
          <div className="mb-1">
            Total: {data.recibo.currency}: {fNumber(data.recibo.total)}
          </div>
          <div className="mb-1">A cuenta: {fNumber(data.recibo.a_cuenta)}</div>
          <div className="mb-40">
            Saldo:{" "}
            {fNumber(
              parseFloat(data.recibo.total) - parseFloat(data.recibo.a_cuenta)
            )}
          </div>
          <div class="flex justify-between">
            <div class="w-1/2 ">
              <Firma
                title="Realizado por"
                user={data.recibo.user.name}
                url={data.recibo.user?.firma?.firma}
              />
            </div>
            <div class="w-1/2">
              <Firma title="cliente" client={data.client.business_name} />
            </div>
          </div>
          <div className="text-right">
            <div>Fecha: {dateConfig(data.created_at)}</div>
            <div className="font-bold text-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ComprobantePrintTicket;
