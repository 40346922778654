import { size, sumBy } from "lodash";
import { useSelector } from "react-redux";
import Firma from "../../components/Firma";
import { dateConfig } from "../../utils/dateconfig";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import {
  calculeTotalAndDiscount,
  calculeTotalDiscount,
} from "../../utils/operations";
import PrintFull from "./PrintFull";
import { getSaleVoucherPrint } from "../../services/order";
import { useEffect, useState } from "react";
import { parse } from "date-fns/esm";

const Print = ({ data, tipe = false }) => {
  const [dataPrint, setDataPrint] = useState();
  const printNormal = useSelector((state) => state.eventButton);
  const getVoucher = () => {
    getSaleVoucherPrint({ id: data.id || data.voucher_head_id }).then((res) => {
      setDataPrint(res);
    });
  };
  useEffect(() => {
    if (!dataPrint?.voucher_detail) {
      getVoucher();
    }
  }, []);

  return (
    <div>
      {size(dataPrint) > 0 && (
        <div>
          {!printNormal ? (
            <PrintFull data={dataPrint} />
          ) : (
            <div
              style={{ fontFamily: "Arial, Verdana, Helvetica", color: "#000" }}
            >
              <div className="">
                <div className="py-2">
                  <div className="text-center text-xl font-bold">
                    RECIBO
                    {dataPrint.recibo.anticipo_id && " DE ANTICIPO"}
                    {dataPrint.recibo.account_id && " DE CUENTA POR COBRAR"}
                  </div>
                  <div className="text-center text-xs font-bold">
                    Nombre empresa
                  </div>
                </div>
                <div className="text-center text-xs font-bold mb-1">
                  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                </div>
                <div className="text-xs pl-2">
                  <div className="text-xs mb-1">
                    Nro Recibo: {dataPrint.recibo.number}
                  </div>
                  <div className="text-xs mb-1">
                    Cliente: {dataPrint.client.business_name}
                  </div>
                  <div className="text-xs mb-1">
                    NIT/CI: {dataPrint.client.identification_number}
                  </div>
                  <div>
                    Numero de Orden: {fInvoiceNumber(dataPrint.voucher_number)}
                  </div>
                  <div>Usuario: {dataPrint.recibo.user?.name}</div>
                </div>
                <div className="border-double border-t-4 border-b-4 border-l-0 border-r-0 border-gray-900 my-3">
                  <div className="flex text-sm pt-1 px-1">
                    <span className="w-3/6">DETALLE</span>
                    <span className="w-1/6 text-right">CANT</span>
                    <span className="w-2/6 text-right">P. UNIT</span>
                    <span className="w-2/6 text-right">DESC</span>
                    <span className="w-2/6 text-right">SUBTOTAL</span>
                  </div>
                  {dataPrint.voucher_detail.map((row, key) => (
                    <div
                      key={key}
                      className="border-dashed border-t border-b border-l-0 border-r-0 border-gray-900 mt-1 my-2 py-2 px-1"
                    >
                      <div className="flex justify-between text-sm">
                        <span className="truncate">{row.item}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="w-3/6 truncate">.</span>
                        <span className="w-1/6 text-right">{row.qty}</span>
                        <span className="w-2/6 text-right">
                          {fNumber(row.price)}
                        </span>
                        <span className="w-2/6 text-right">
                          {fNumber(parseFloat(row.discount))}
                        </span>
                        <span className="w-2/6 text-right">
                          {fNumber(
                            parseFloat(row.qty) * parseFloat(row.price) -
                              parseFloat(row.discount)
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between text-sm">
                    <span className="">{dataPrint.recibo.destail}</span>
                  </div>
                </div>
                <div className="text-xs">
                  <div className="mb-1">
                    Sub Total:{" "}
                    {fNumber(
                      sumBy(dataPrint.voucher_detail, (o) => {
                        return (
                          parseInt(o.qty) * parseInt(o.price) -
                          parseFloat(o.discount)
                        );
                      })
                    )}
                  </div>
                  <div className="mb-1">
                    Descuento: Bs {fNumber(dataPrint.discount.discount_amount)}
                  </div>
                  <div className="mb-1">
                    Total: {fNumber(dataPrint.recibo.total)}
                  </div>
                  <div className="mb-1">
                    A cuenta: {fNumber(dataPrint.recibo.a_cuenta)}
                  </div>
                  <div className="mb-40">
                    Saldo:{" "}
                    {fNumber(
                      parseFloat(dataPrint.recibo.total) -
                        parseFloat(dataPrint.recibo.a_cuenta)
                    )}
                  </div>
                  <div class="flex justify-between">
                    <div class="w-1/2 ">
                      <Firma
                        user={dataPrint.recibo.user?.name}
                        title="Recibí conforme"
                        url={dataPrint.recibo.user?.firma?.firma}
                      />
                    </div>
                    <div class="w-1/2">
                      <Firma
                        user="____________________"
                        title="Entregué conforme"
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <div>Fecha: {dateConfig(dataPrint.recibo.created_at)}</div>
                    <div className="font-bold text-sm">
                      {dataPrint.recibo.payment_type}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Print;
