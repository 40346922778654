import React from "react";
import logo from "../logo.png";
import logo1 from "../logo-comexa.png";
import { useSelector } from "react-redux";
function Logo({ empresa = null }) {
  const userData = useSelector((state) => state.userData);

  if (empresa === "jacha") {
    return <img src={logo} alt="logo" width={"auto"} className="h-16" />;
  }
  return (
    <img
      src={userData.store + "" === "1" ? logo : logo}
      alt="logo"
      width={"auto"}
      className="h-16"
    />
  );
}

export default Logo;
