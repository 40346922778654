import React from "react";

const BackdropLoad = () => {
  return (
    <div class="spinnerContainer2" id="loader2">
      <div class="spinner"></div>
      <div class="loader">
        <p>loading</p>
        <div class="words">
          <span class="word">Cargando 1</span>
          <span class="word">Cargando 2</span>
          <span class="word">Cargando 3</span>
          <span class="word">Cargando 4</span>
        </div>
      </div>
    </div>
  );
};

export default BackdropLoad;
